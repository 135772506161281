import { TotalPrice } from "@/components/domains";
import { CartModel } from "@/models/cart/type";

import styles from "./TotalPricePanel.module.scss";

export interface TotalPricePanelProps {
  cart: CartModel;
}

export function TotalPricePanel({ cart }: TotalPricePanelProps): React.ReactNode {
  return (
    <div className="text__right mg__top__s pd__bottom__m pd__bottom__off__pc">
      <dl className={styles.container}>
        <dt className="text__left">
          <span className="text__m clear__pc">合計：</span>
        </dt>
        <dd className="text__right">
          <span className="text__m clear__sp inline">合計：</span>
          <TotalPrice cart={cart} className="text__xxl" />
        </dd>
      </dl>
      {cart.isSubscription && (
        <p className="text__s">
          2回目以降のご購入から
          <TotalPrice cart={cart} isFirst={false} />
        </p>
      )}
      <p className="text__s text__right text__gray__dark mg__top__s">
        ※価格はすべて税込表示 *税率8%
      </p>
    </div>
  );
}
