"use client";

import { FormProvider, useForm } from "react-hook-form";

import { useAmazonPay } from "@/components/domains";
import { getDefaultFormValues } from "@/components/domains/yearplan/PointPlanForm/helpers";
import { PointPlanValues } from "@/components/domains/yearplan/PointPlanForm/schema";

import { BodyChangeSection } from "./BodyChangeSection";
import { CautionSection } from "./CautionSection";
import { HeaderSection } from "./HeaderSection";
import { MeritSection } from "./MeritSection";
import { PlanSection } from "./PlanSection";
import { PurchaseFormSection } from "./PurchaseFormSection";
import { QuestionSection } from "./QuestionSection";

export function YearPlan(): React.ReactNode {
  const { amazonPayEnable, amazonPayCheckoutSessionId } = useAmazonPay();

  const formContext = useForm<PointPlanValues>({
    defaultValues: getDefaultFormValues(amazonPayEnable, amazonPayCheckoutSessionId),
  });

  return (
    <FormProvider {...formContext}>
      <HeaderSection />
      <PlanSection />
      <MeritSection />
      <BodyChangeSection />
      <QuestionSection />
      <PurchaseFormSection />
      <CautionSection />
    </FormProvider>
  );
}
