"use client";

import { useCallback, useState } from "react";

import { ImageLegacy } from "@/components/displays";
import { fireKarteEvent } from "@/utils";

import styles from "./CopyUrlBox.module.scss";

interface CopyUrlBoxProps {
  shareUrl: string;
}

export function CopyUrlBox({ shareUrl }: CopyUrlBoxProps): React.ReactNode {
  const [copied, setCopied] = useState(false);
  const copyURL = useCallback(async () => {
    await navigator.clipboard.writeText(shareUrl);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
    fireKarteEvent("invite_url_share", { type: "copy" });
  }, [shareUrl]);

  return (
    <dl className={styles.box}>
      <dt className={styles.text}>
        <p className={`${styles.url} ${copied ? styles.copied : ""}`}>
          {copied ? "コピーしました" : shareUrl}
        </p>
      </dt>
      <dd className={styles.copyButton} onClick={copyURL}>
        <ImageLegacy
          src="https://asset.basefood.co.jp/images/parts/copy.svg"
          size={{ width: 17, height: 20 }}
          alt="copy"
        />
      </dd>
    </dl>
  );
}
