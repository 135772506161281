import clsx from "clsx";
import NextImage, { StaticImageData } from "next/image";

import { Column } from "@/components/containers";
import { SpotlightFrame } from "@/components/displays";

import funContents01 from "$/images/mile/mileprogram-fun-contents-01.png";
import funContents02 from "$/images/mile/mileprogram-fun-contents-02.png";
import funContents03 from "$/images/mile/mileprogram-fun-contents-03.png";
import funContents04 from "$/images/mile/mileprogram-fun-contents-04.png";
import funContents05 from "$/images/mile/mileprogram-fun-contents-05.png";
import funContents06 from "$/images/mile/mileprogram-fun-contents-06.png";
import funContents07 from "$/images/mile/mileprogram-fun-contents-07.png";
import funContents08 from "$/images/mile/mileprogram-fun-contents-08.png";

import styles from "./MileProgramFunContents.module.scss";

type BadgePotionVert = "top" | "bottom";
type BadgePotionHoriz = "left" | "right";
type BadgePosition = `${BadgePotionVert}-${BadgePotionHoriz}`;

type FunContent = {
  image: StaticImageData;
  badge?: {
    label: string;
    position: BadgePosition;
    color: "dark" | "light";
  };
};

const funContents: FunContent[] = [
  {
    image: funContents01,
  },
  {
    image: funContents02,
    badge: {
      label: "新商品\n発表🎊",
      position: "bottom-left",
      color: "dark",
    },
  },
  {
    image: funContents03,
    badge: {
      label: "クイズ\n大会🥳",
      position: "top-right",
      color: "light",
    },
  },
  {
    image: funContents04,
    badge: {
      label: "豪華な\n景品🎁",
      position: "bottom-right",
      color: "dark",
    },
  },
  {
    image: funContents05,
    badge: {
      label: "工場\n動画🎥",
      position: "bottom-left",
      color: "light",
    },
  },
  {
    image: funContents06,
  },
  {
    image: funContents07,
    badge: {
      label: "開発\n秘話🍞",
      position: "bottom-left",
      color: "dark",
    },
  },
  {
    image: funContents08,
  },
];

export function MileProgramFunContents(): React.ReactNode {
  return (
    <Column className={clsx("mg__bottom__l", styles.root)}>
      <SpotlightFrame color="#5a5a5a" height={60}>
        <p className={clsx("text__center", "text__bold", styles.inviteMessage)}>
          見て参加して楽しいコンテンツを
          <br />
          ご用意しています！
        </p>
      </SpotlightFrame>
      <div className={clsx(styles.imagesContainer, styles.clearSp)}>
        {funContents.map((content) => {
          const { badge, image } = content;
          const [vert, horiz] = (badge?.position.split("-") ?? []) as [
            BadgePotionVert,
            BadgePotionHoriz,
          ];
          const positionClass = clsx(
            vert && styles[`badgePotion${vert}`],
            horiz && styles[`badgePotion${horiz}`]
          );
          const colorClass = styles[`badgeColor${badge?.color ?? "dark"}`];
          return (
            <div key={image.src} className={styles.imageContainer}>
              {badge && (
                <div className={clsx(styles.badge, positionClass, colorClass)}>
                  <p className={clsx("text__white", "text__s", "text__center", "text__bold")}>
                    {badge.label}
                  </p>
                </div>
              )}
              <NextImage src={image} alt={image.src} />
            </div>
          );
        })}
      </div>
      <div className={clsx(styles.imagesContainer, styles.clearPc)}>
        <div className={styles.imageContainer}>
          <NextImage src={funContents01} alt={funContents01.src} />
        </div>
        <div className={styles.imageContainer}>
          <NextImage src={funContents02} alt={funContents02.src} />
          <div
            className={clsx(
              styles.badge,
              styles.badgePotiontop,
              styles.badgePotionright,
              styles.badgeColordark
            )}
          >
            <p className={clsx("text__white", "text__s", "text__center", "text__bold")}>
              新商品
              <br />
              発表🎊
            </p>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <NextImage src={funContents03} alt={funContents03.src} />
          <div
            className={clsx(
              styles.badge,
              styles.badgePotiontop,
              styles.badgePotionleft,
              styles.badgeColorlight
            )}
          >
            <p className={clsx("text__white", "text__s", "text__center", "text__bold")}>
              クイズ
              <br />
              大会🥳
            </p>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <NextImage src={funContents05} alt={funContents05.src} />
          <div
            className={clsx(
              styles.badge,
              styles.badgePotionbottom,
              styles.badgePotionright,
              styles.badgeColorlight
            )}
          >
            <p className={clsx("text__white", "text__s", "text__center", "text__bold")}>
              工場
              <br />
              動画🎥
            </p>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <NextImage src={funContents08} alt={funContents08.src} />
          <div
            className={clsx(
              styles.badge,
              styles.badgePotionbottom,
              styles.badgePotionleft,
              styles.badgeColordark
            )}
          >
            <p className={clsx("text__white", "text__s", "text__center", "text__bold")}>
              豪華な
              <br />
              景品🎁
            </p>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <NextImage src={funContents06} alt={funContents06.src} />
        </div>
        <div className={styles.imageContainer}>
          <NextImage src={funContents04} alt={funContents04.src} />
        </div>
        <div className={styles.imageContainer}>
          <NextImage src={funContents07} alt={funContents07.src} />
          <div
            className={clsx(
              styles.badge,
              styles.badgePotionbottom,
              styles.badgePotionright,
              styles.badgeColordark
            )}
          >
            <p className={clsx("text__white", "text__s", "text__center", "text__bold")}>
              開発
              <br />
              秘話🍞
            </p>
          </div>
        </div>
      </div>
      <div className="text__right">
        <a
          href="https://basefood.co.jp/magazine/column/6066/"
          className={clsx(styles.report, "text__s text__black")}
        >
          BASE FOOD SUMMITの詳細レポートはこちら&#8599;
        </a>
      </div>
    </Column>
  );
}
