import clsx from "clsx";

import { Column, Row } from "@/components/containers";

import styles from "./RankBenefitItem.module.scss";

interface RankBenefitItem {
  rankImage: string;
  label: string;
  threshold: number;
  benefit: string;
  benefitImage: { src: string };
  benefitDescription: string;
  caution: string;
}

interface RankBenefitItemProps {
  rankName: string;
  index: number;
  rankBenefit: RankBenefitItem;
  qualifiedRanks: RankBenefitItem[];
  border?: boolean;
}

export function RankBenefitItem({
  rankName,
  rankBenefit,
  index,
  qualifiedRanks,
  border,
}: RankBenefitItemProps): React.ReactNode {
  const rankIndex = (index + 1).toString().padStart(2, "0");
  return (
    <Column className={clsx(styles.root, border && styles.rootBorder)}>
      <span id={`rank-benefits-${rankName}`} className={styles.pageAnchor} />
      <p className={clsx("text__l", styles.rankBenefitDetailItemIndex)}>特典 {rankIndex}</p>
      <img
        src={rankBenefit.benefitImage.src}
        alt={rankBenefit.benefit}
        className={styles.rankBenefitImage}
      />
      <h4 className={clsx("text__bold", "text__l")}>{rankBenefit.benefit}</h4>
      <p className={clsx("text__m", "text__black")}>{rankBenefit.benefitDescription}</p>
      {rankBenefit.caution ? (
        <p className={clsx("text__s", "text__gray__dark")}>{rankBenefit.caution}</p>
      ) : (
        <div className={styles.spacer} />
      )}

      <Row className={styles.qualifiedRanks}>
        <p className={clsx("text__s")}>{rankBenefit.label}</p>
        <Row>
          {qualifiedRanks.map((rank) => (
            <img key={rank.label} src={rank.rankImage} alt={rank.label} />
          ))}
        </Row>
      </Row>
    </Column>
  );
}
