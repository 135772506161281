"use client";

import { Column } from "@/components/containers";
import { MileHistories } from "@/components/domains/mile";

import styles from "./MileHistory.module.scss";

export function MileHistory(): React.ReactNode {
  return (
    <section className={styles.container}>
      <Column align="center" className={styles.inner}>
        <MileHistories titleClassName={styles.title} />
      </Column>
    </section>
  );
}
