import clsx from "clsx";

import { ImageLegacy, Scroll } from "@/components/displays";

import styles from "./MeritSection.module.scss";

export function MeritSection(): React.ReactNode {
  return (
    <section className="bg__red pd__top__xl pd__bottom__xl">
      <div className="container">
        <div className="row">
          <div className="col-12 col-m-10 col-offset-m-1">
            <h4 className="text__center mg__bottom__s">
              <ImageLegacy
                src="https://asset.basefood.co.jp/images/parts/txt_pointpack_merit.png"
                alt="ポイントパックの3つのメリット"
                className="full__image"
                size={{ width: 247, height: 88 }}
              />
            </h4>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-m-4 pd__top__m">
                <div className={clsx("bg__white", styles.merit)}>
                  <span className={styles.number}>1</span>
                  <p className="text__red__dark text__bold text__l text__center mg__bottom__m">
                    お得な継続コースを
                    <br />
                    さらにお得に利用できる
                  </p>
                  <p className="text__m">
                    レギュラープランなら、72,600ポイントを66,000円で購入可能。ライトプランなら21,000ポイントを20,000円で購入可能。1ポイント=1円だから、
                    <span className="text__bold">
                      通常10%オフの継続コースをよりお得に続けることができます。
                    </span>
                  </p>
                </div>
              </div>

              <div className="col-12 col-m-4 pd__top__m">
                <div className={clsx("bg__white", styles.merit)}>
                  <span className={styles.number}>2</span>
                  <p className="text__red__dark text__bold text__l text__center mg__bottom__m">
                    毎日の食事の迷いを解消
                  </p>
                  <p className="text__m">
                    ポイントをまとめてお得に購入しBASE FOODをつづけることで、
                    <span className="text__bold">
                      食への迷いをなくし、ラクに栄養バランスのよい食生活を習慣化することができます。
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-12 col-m-4 pd__top__m">
                <div className={clsx("bg__white", styles.merit)}>
                  <span className={styles.number}>3</span>
                  <p className="text__red__dark text__bold text__l text__center mg__bottom__m">
                    ポイントは2年間保証
                    <br />
                    環境の変化にも対応<span className="wsnr">できるから</span>安心
                  </p>
                  <p className="text__m">
                    ポイントの有効期限は2年間。スキップやお届け日変更もできるから、
                    <span className="text__bold">ライフスタイルにあわせて利用できます。</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 text__center pd__top__l">
            <Scroll to="yearplanPurchase" className="btn inline round gray text__m">
              お得なポイントを購入する
            </Scroll>
          </div>
        </div>
      </div>
    </section>
  );
}
