import { Breadcrumb } from "@/components/layouts";

import styles from "./InviteHeaderSection.module.scss";

const items = [
  {
    path: "/",
    title: "HOME",
  },
  {
    path: "/mypage",
    title: "マイページ",
  },
];

export function InviteHeaderSection(): React.ReactNode {
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <Breadcrumb current="友達紹介キャンペーン" items={items} textClassName="text__blue" />
      </div>
      <div className={styles.headerImage} />
    </section>
  );
}
