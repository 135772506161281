import { useCallback, useEffect, useState, useRef } from "react";

export const useHandleScroll = () => {
  const scrollLimitBaseElement = useRef<HTMLDivElement>(null);
  const [isScrollLimit, setIsScrollLimit] = useState(true);

  const checkScrollLimit = useCallback(() => {
    const elem = scrollLimitBaseElement?.current;
    if (elem) {
      const height = elem.offsetTop + 36;
      if (window.scrollY > height) {
        setIsScrollLimit(false);
      } else {
        setIsScrollLimit(true);
      }
    }
  }, [scrollLimitBaseElement]);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollLimit);

    return () => {
      window.removeEventListener("scroll", checkScrollLimit);
    };
  }, [checkScrollLimit]);

  return {
    isScrollLimit,
    scrollLimitBaseElement,
  };
};
