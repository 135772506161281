import clsx from "clsx";

import { Breadcrumb } from "@/components/layouts";

import styles from "./HeaderSection.module.scss";

export function HeaderSection(): React.ReactNode {
  return (
    <section className={clsx("bg__orange", styles.topPadding)}>
      <div className="container">
        <Breadcrumb
          items={[
            { path: "/", title: "HOME" },
            { path: "/mypage", title: "マイページ" },
          ]}
          current="ポイントパック"
        />
      </div>
      <div className={styles.heroImage}></div>
    </section>
  );
}
