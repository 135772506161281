import React from "react";

import clsx from "clsx";

import { ImageLegacy } from "@/components/displays";

import styles from "./DoctorSection.module.scss";

export function DoctorSection(): React.ReactNode {
  return (
    <section className="pd__top__xl pd__bottom__xl bg__gray">
      <div className="container">
        <div className="row">
          <div className="col-12 col-m-10 col-offset-m-1 text__center">
            <h4 className="text__bold text__xl mg__bottom__l">医学専門家も推薦</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-m-5 col-offset-m-1">
            <ImageLegacy
              src="https://asset.basefood.co.jp/lp/form_02/doctor.png"
              alt="医学部 准教授"
              containerClassName={styles.image}
              aspectRatio="130/81"
            />
            <p className="text__s">
              カリフォルニア大学 ロサンゼルス校（UCLA）
              <br />
              医学部 准教授
            </p>
            <p className="text__m text__bold mg__bottom__s mg__top__s">津川 友介</p>
            <p className="text__m text__justify mg__bottom__l mg__bottom__off clear__sp">
              聖路加国際病院で医師として勤務した後、世界銀行を経て、ハーバード大学で修士号と博士号を取得。2017年より現職。著書に10万部突破のベストセラー『世界一シンプルで科学的に証明された究極の食事』（東洋経済新報社、2018年）がある。
            </p>
          </div>

          <div className="col-12 col-m-5">
            <div className={clsx("bg__white", styles.doctor)}>
              <p className="text__justify mg__bottom__m">
                BASE FOODはからだにいいと証明されている全粒穀物を使用しています。その全粒粉に加え、
                <span className={clsx("text__bold", styles.markpen)}>
                  昆布や大豆など栄養価の高い原材料
                </span>
                がブレンドされたBASE
                FOODは、栄養バランスもととのっており、からだにいいと考えられます。
              </p>
              <p className="text__justify">
                また、食事は、
                <span className={clsx("text__bold", styles.markpen)}>短距離走でなくマラソン</span>
                です。続けられなければ意味がなく、たとえば極端な糖質制限食は、短期的には効果があるものの、長期的には効果がないと報告されています。BASE
                FOODは、おやつを置き換える形でむりなく継続できるため、健康や美容への効果が期待できます。
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
