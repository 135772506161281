import { ImageLegacy } from "@/components/displays";

import ImgSafetyBanner from "$/images/lp/safety_banner.jpg";

export function SafetyBanner(): React.ReactNode {
  return (
    <section className="bg__gray pd__top__l">
      <div className="container">
        <div className="row">
          <div className="col-12 col-m-6 col-offset-m-3">
            <a href="https://basefood.co.jp/safety" target="_blank">
              <ImageLegacy src={ImgSafetyBanner} alt="安心・安全の取り組み" aspectRatio="119/45" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
