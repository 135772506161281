"use client";

import { ScrollAnchor } from "@/components/displays";
import PointPlanForm from "@/components/domains/yearplan/PointPlanForm";

import styles from "./PurchaseFormSection.module.scss";

export function PurchaseFormSection(): React.ReactNode {
  return (
    <ScrollAnchor id="yearplanPurchase" as="section" className={styles.root}>
      <div className="container pd__top__xl pd__bottom__l">
        <div className="row">
          <div className="col-12 col-m-10 col-offset-m-1">
            <PointPlanForm />
          </div>
        </div>
      </div>
    </ScrollAnchor>
  );
}
