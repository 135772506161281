"use client";

import { useCallback, useEffect, useMemo } from "react";

import { keepPreviousData } from "@tanstack/react-query";
import Link from "next/link";

import { ProductList, useAuth } from "@/components/domains";
import { useGetParsedCartFromCartItems } from "@/queries";
import { useClientFrontStoreCartItems } from "@/storage";
import { withCsr } from "@/utils";
import { sumBy } from "@/utils/array";
import { useRemoveSuspendedProductsFromCart } from "@/utils/hooks";

import { CartHeader } from "./CartHeader";
import { CheckoutButtons } from "./CheckoutButtons";
import { FooterButtons } from "./FooterButtons";
import { useHandleScroll } from "./helper";
import { SubscriptionSection } from "./SubscriptionSection";
import { TotalPricePanel } from "./TotalPricePanel";

export const Cart = withCsr(function Cart(): React.ReactNode {
  const { cartItems, setCartItems } = useClientFrontStoreCartItems();
  const {
    data: { cart },
  } = useGetParsedCartFromCartItems({
    query: {
      // `placeholderData: keepPreviousData`はcart情報が変更された際の以下の2点を防ぎます。
      // - cartがundefinedになり、各コンポーネントのstateがリセットされるのを防ぐ
      // - 大きなレイアウトシフトが発生することを防ぐ
      placeholderData: keepPreviousData,
    },
  });
  useRemoveSuspendedProductsFromCart(cart);

  const totalQuantity = useMemo(() => sumBy(cartItems, "quantity"), [cartItems]);
  const { isLoggedIn } = useAuth();
  const { isScrollLimit, scrollLimitBaseElement } = useHandleScroll();

  const toggleCartSubscription = useCallback(() => {
    const newCartItems = cartItems.map((item) => ({
      ...item,
      subscription: !item.subscription,
    }));
    setCartItems(newCartItems);
  }, [cartItems, setCartItems]);

  const products = useMemo(() => cart?.products ?? [], [cart]);
  const quantityMap = useMemo(() => {
    const map = new Map<number, number>();
    cartItems.forEach((item) => {
      map.set(item.variantId, item.quantity);
    });
    return map;
  }, [cartItems]);

  const changeQuantity = useCallback(
    (quantity: number, variantId?: number) => {
      const newCartItems = cartItems.map((item) => {
        if (item.variantId === variantId) {
          return {
            ...item,
            quantity,
          };
        }
        return item;
      });
      setCartItems(newCartItems);
    },
    [cartItems, setCartItems]
  );

  useEffect(() => {
    // SPモードで遷移してきた場合、ページトップにスクロールしない問題を解消するため
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {cart && <CartHeader cart={cart} isScrollLimit={isScrollLimit} />}
      {totalQuantity === 0 ? (
        <div className="row pd__bottom__xl">
          <div className="col-12 text__center">
            <p className="mg__top__l mg__bottom__m">ショッピングカートが空です。</p>
            <Link href="/" className="btn yellow inline round text__black">
              買い物を続ける
            </Link>
          </div>
        </div>
      ) : (
        <div className="row pd__bottom__xl pd__top__off__pc">
          {/*idはCartHeaderのスクロール処理で使用*/}
          <div className="col-12 col-m-8 clear__pc">
            <div ref={scrollLimitBaseElement}>
              {cart && (
                <SubscriptionSection
                  cart={cart}
                  subscriptionEnabled={cart.isSubscription}
                  setSubscriptionEnabled={toggleCartSubscription}
                />
              )}
            </div>
          </div>
          <div className="col-12 col-m-8">
            {cart && (
              <>
                {cart.normalProducts && cart.normalProducts.length > 0 && (
                  <ProductList
                    cart={cart}
                    products={cart.normalProducts}
                    quantityMap={quantityMap}
                    changeQuantity={changeQuantity}
                    temperature={"normal"}
                  />
                )}
                {cart.freezeProducts && cart.freezeProducts.length > 0 && (
                  <ProductList
                    cart={cart}
                    products={cart.freezeProducts}
                    quantityMap={quantityMap}
                    changeQuantity={changeQuantity}
                    temperature={"freeze"}
                  />
                )}
              </>
            )}

            {products.length > 0 && (
              <p className="text__s text__right text__gray__dark mg__top__s clear__sp">
                ※価格はすべて税込表示 *税率8%
              </p>
            )}
          </div>

          <div className="col-12 col-m-4">
            <div className="clear__sp">
              {cart && (
                <SubscriptionSection
                  cart={cart}
                  subscriptionEnabled={cart.isSubscription}
                  setSubscriptionEnabled={toggleCartSubscription}
                />
              )}
            </div>
            {cart && <TotalPricePanel cart={cart} />}
            {cart && (
              <div className="mg__top__l mg__bottom__l text__right clear__sp">
                <CheckoutButtons cart={cart} isLoggedIn={isLoggedIn} cartItems={cartItems} />
              </div>
            )}
          </div>
          {cart && <FooterButtons cart={cart} cartItems={cartItems} isLoggedIn={isLoggedIn} />}
        </div>
      )}
    </>
  );
});
