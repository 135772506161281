import { getInviteCode } from "@/generated/open-api/customer/customer";

export type Media = "twitter" | "facebook" | "line";

export function fireGtag(media: Media) {
  const eventLabel = media === "twitter" ? "x" : media;
  window.gtag("event", "click", { event_category: "invite_share_media", event_label: eventLabel });
}

export function getSocialMediaUrl(media: Media, url: string) {
  switch (media) {
    case "twitter":
      const inviteTextTwitter =
        "完全栄養パン 「ベースブレッド」を試しませんか？下の紹介URLから購入すると、初回2000円OFF！※個人情報は双方に通知されません。";
      return `https://twitter.com/share?text=${inviteTextTwitter}&hashtags=BASEFOOD,ベースフード,完全栄養食&url=${encodeURIComponent(url)}`;
    case "facebook":
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    case "line":
      const inviteTextLine = `完全栄養パン 「ベースブレッド」を試しませんか？下の紹介URLから購入すると、初回2000円OFF！※個人情報は双方に通知されません。${url}`;
      return `https://line.me/R/msg/text/?${inviteTextLine}`;
    default:
      return "";
  }
}

export async function fetchInviteUrl() {
  const res = await getInviteCode();
  if (res?.code) {
    return `https://${location.host}/lp/invite?code=${encodeURI(res.code)}`;
  } else {
    throw new Error("Failed to fetch invite code");
  }
}
